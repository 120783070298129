.schedule-div {
    border-bottom-left-radius: 4% 35%;
    border-top-left-radius: 4% 35%;
    border-bottom-right-radius: 4% 35%;
    border-top-right-radius: 4% 35%;
    background-color: #909BB4;
}

#view-outpatient-services-btn:hover {
    background-color: #db6943;
}

#view-available-lab-services-btn:hover {
    background-color: #7688b3;
}

.tab-toggler.active {
    background-color: #2098ee;
}

.lab-services-cards-div {
    width: 85%;
}

.bg-outpatient-services {
    background-color: #D5896F;
}

.bg-lab-services {
    background-color: #909BB4;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .card-text {
        font-size: 1em;
        line-height: 1.5em;
    }

    .card-title {
        font-size: 1.4em;
    }

    .schedule-div {
        border-bottom-left-radius: 4% 15%;
        border-top-left-radius: 4% 15%;
        border-bottom-right-radius: 4% 15%;
        border-top-right-radius: 4% 15%;
    }

    .lab-services-cards-div {
        width: auto;
        margin: 3em 0;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .schedule-div {
        border-bottom-left-radius: 3% 38%;
        border-top-left-radius: 3% 38%;
        border-bottom-right-radius: 3% 38%;
        border-top-right-radius: 3% 38%;
    }
}


@media (min-width: 1440px) {
    .schedule-div {
        border-bottom-left-radius: 6% 38%;
        border-top-left-radius: 6% 38%;
        border-bottom-right-radius: 6% 38%;
        border-top-right-radius: 6% 38%;
    }
}
