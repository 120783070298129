/*
Bootstrap .row spacing
*/

body, html {
    height:100%;
}

.row > * {
    padding-left: 0;
    padding-right: 0;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.g-recaptcha > div {
    margin-left: auto;
    margin-right: auto;
}

.mb-n5 {
    margin: -2.5em;
}

.btn:hover {
    background-color: #ACB4C6;
}

.submit-btn {
    background-color: #2098ee !important;
    color: white !important;
}

.w-90 {
    width: 90%;
}

.navbar-brand-link {
    padding-bottom: 3px;
    color: black;
}

/*   font classes */
.kaisei-font {
    font-family: 'Kaisei HarunoUmi', serif;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .w-md-75 {
        width: 75%;
    }

    .w-md-50 {
        width: 50% !important;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .w-xl-50 {
        width: 50%;
    }
}


@media (min-width: 1440px) {
}
